body {
	margin: 0;
	/* overflow: initial !important; */
	padding: 0 !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
textarea,
input {
	cursor: default;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mailchimpform > input,
.mailchimpform > button {
	display: block;
	font-size: 1.2rem;
	margin: 0 auto 20px auto;
	width: 100%;
}

@media screen and (max-width: 600px) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		overflow-wrap: break-word;
	}
}

@media (max-width: 767px) {
	html,
	body {
		overflow-x: hidden !important;
	}
}

.products-plx {
	top: 30% !important;
	left: initial !important;
	right: 10%;
}

.bg-1 {
	top: initial !important;
	left: -10% !important;
	bottom: -10%;
	width: 100%;
}

.bg-2 {
	top: initial !important;
	left: initial !important;
	right: -10%;
	bottom: -20%;
	width: 120%;
}

.bg-3 {
	top: -5% !important;
	left: -5% !important;
}

.slick-prev:before,
.slick-next:before {
	color: black !important;
}

.set-bg {
	position: relative;
}

.set-bg-btn {
	left: initial;
	top: initial;
	bottom: 25px;
	position: absolute !important;
	right: 25px;
	z-index: 999;
}

[data-ref='editable']:hover .access-ui {
	opacity: 1;
	visibility: visible;
}

.loading-section {
	background-color: #eee;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	height: 100vh;
	width: 100vw;
	z-index: 999999;
}

.loading {
	background-color: #eee;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999;
}

.mailchimpform input,
.mailchimpform button {
	display: block;
	font-size: 1.2rem;
	margin: 0 auto 20px auto;
	width: 100%;
}

@media screen and (max-width: 600px) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		overflow-wrap: break-word;
	}
}

@media (max-width: 767px) {
	html,
	body {
		overflow-x: hidden !important;
	}
}

[data-ref='editable'] {
	position: relative;
}

.access-ui {
	left: 50%;
	position: absolute !important;
	opacity: 0;
	top: 50%;
	transform: translate(-50%, -50%);
	visibility: invisible;
}

[data-ref='editable']:hover .access-ui {
	opacity: 1;
	visibility: visible;
}

.site-nav {
	background-color: rgba(0, 0, 0, 0.2);
	bottom: 20px;
	opacity: 0.6;
	padding: 10px;
	position: fixed;
	right: 20px;
	z-index: 99;
	transition: all 0.2s ease-in-out;
}

.site-nav:hover {
	background-color: rgba(0, 0, 0, 0.4);
	opacity: 1;
}
.site-nav ul,
.site-nav li {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-nav li {
	font-size: 0.8rem;
	text-transform: lowercase;
}

.site-nav li.active a {
	text-shadow: 0 1px black;
}

.site-nav a {
	color: #fff;
	text-decoration: none;
	text-transform: capitalize;
}

input.page-button[type='radio'] {
	appearance: none;
	width: 8px;
	height: 8px;
	border: 1px solid #fff;
	border-radius: 50%;
	outline: none;
	cursor: pointer;
}

input.page-button[type='radio']:checked::before {
	background-color: #fff;
}

input.page-button[type='radio']:checked {
	border: 1px solid #fff;
}

input.page-button[type='radio']::before {
	content: '';
	display: block;
	width: 8px;
	height: 8px;
	/* margin: 2px; */
	background-color: #fff;
	border-radius: 50%;
	visibility: hidden;
}

input.page-button[type='radio']:checked::before {
	visibility: visible;
}

.App {
	height: 100vh;
	width: 100vw;
}

.map-container {
	height: 100%;
	width: 100%;
}

[data-ref='set-bg'] {
	position: relative;
}

.set-bg-btn {
	left: initial;
	top: initial;
	bottom: 25px;
	position: absolute !important;
	right: 25px;
	z-index: 999;
}

[data-ref='editable']:hover .access-ui {
	opacity: 1;
	visibility: visible;
}

.loading {
	background-color: #eee;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999;
}

.mailchimpform input,
.mailchimpform button {
	display: block;
	font-size: 1.2rem;
	margin: 0 auto 20px auto;
	width: 100%;
}

@media screen and (max-width: 600px) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		overflow-wrap: break-word;
	}
}

@media (max-width: 767px) {
	html,
	body {
		overflow-x: hidden !important;
	}
}

[data-ref='editable'] {
	position: relative;
}

.access-ui {
	left: 50%;
	position: absolute !important;
	opacity: 0;
	top: 50%;
	transform: translate(-50%, -50%);
	visibility: invisible;
}

[data-ref='editable']:hover .access-ui {
	opacity: 1;
	visibility: visible;
}

.site-nav {
	background-color: rgba(0, 0, 0, 0.2);
	bottom: 20px;
	opacity: 0.6;
	padding: 10px;
	position: fixed;
	right: 20px;
	z-index: 99;
	transition: all 0.2s ease-in-out;
}

.site-nav:hover {
	background-color: rgba(0, 0, 0, 0.4);
	opacity: 1;
}
.site-nav ul,
.site-nav li {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-nav li {
	font-size: 0.8rem;
	text-transform: lowercase;
}

.site-nav li.active a {
	text-shadow: 0 1px black;
}

.site-nav a {
	color: #fff;
	text-decoration: none;
	text-transform: capitalize;
}

input.page-button[type='radio'] {
	appearance: none;
	width: 8px;
	height: 8px;
	border: 1px solid #fff;
	border-radius: 50%;
	outline: none;
	cursor: pointer;
}

input.page-button[type='radio']:checked::before {
	background-color: #fff;
}

input.page-button[type='radio']:checked {
	border: 1px solid #fff;
}

input.page-button[type='radio']::before {
	content: '';
	display: block;
	width: 8px;
	height: 8px;
	/* margin: 2px; */
	background-color: #fff;
	border-radius: 50%;
	visibility: hidden;
}

input.page-button[type='radio']:checked::before {
	visibility: visible;
}

.App {
	height: 100vh;
	width: 100vw;
}

.map-container {
	height: 100%;
	width: 100%;
}
